import React from 'react';
import TreeChart from 'react-tree-graph';

import Layout from '../components/layout';
import SEO from '../components/seo';
import TreeYamlData from '../data/trees/a_tree.yml';

import 'react-tree-graph/dist/style.css';

const TreesPage = () => {
  return (
    <Layout>
      <SEO title="A Tree" />
      <h1>Trees</h1>
      <TreeChart
        data={TreeYamlData}
        height="600"
        width="800"
      />
    </Layout>
  );
};

export default TreesPage;
